/** Ionic CSS Variables **/
:root {
  /** primary **/
  // --ion-color-primary: #3880ff;
  --ion-color-primary: #08b783;
  --ion-color-primary-rgb: 8, 183, 131;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #07a173;
  --ion-color-primary-tint: #21be8f;

  /** secondary **/
  --ion-color-secondary: #f8b330;
  --ion-color-secondary-rgb: 248, 179, 48;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #f0a51b;
  --ion-color-secondary-tint: #f6d193;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** primary background **/
  --ion-color-primary-bg: #ffffff;
  --ion-color-primary-bg-rgb: 255, 255, 255;
  --ion-color-primary-bg-contrast: #000000;
  --ion-color-primary-bg-contrast-rgb: 0, 0, 0;
  --ion-color-primary-bg-shade: #e0e0e0;
  --ion-color-primary-bg-tint: #ffffff;

  /** light background **/
  --ion-color-light-bg: #f9fcfb;
  --ion-color-light-bg-rgb: 249, 252, 251;
  --ion-color-light-bg-contrast: #000000;
  --ion-color-light-bg-contrast-rgb: 0, 0, 0;
  --ion-color-light-bg-shade: #dbdedd;
  --ion-color-light-bg-tint: #fafcfb;

  // easeinn colors
  --easeinn-primary-color: #08b783;
}

//Color Reference: https://dribbble.com/shots/7079317-Payment-Dashboard

$easeinn-dark-green-color: #017537;
$easeinn-primary-color: #08b783;
$easeinn-primary-light-color: rgba(8, 183, 131, 0.5);
$easeinn-primary-lightest-color: rgba(205, 243, 232, 0.5);
//$easeinn-primary-color: rgb(68, 0, 255);

$easeinn-dark-color: #2a3643;

$easeinn-primary-accent-color: rgb(255, 255, 255);
$easeinn-dark-accent-color: #2a2643;
$easeinn-secondary-accent-color: rgb(195, 183, 226);

$easeinn-secondary-color: rgb(248, 179, 48);
$easeinn-secondary-dark-color: rgb(240, 165, 27);
$easeinn-secondary-light-color: #f6d193;
//#017537;

//$easeinn-success-color: rgb(0, 223, 19);
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  --ion-color-primary: #08b783;
  --ion-color-primary-rgb: 8, 183, 131;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #07a173;
  --ion-color-primary-tint: #21be8f;

  --ion-color-secondary: #f8b330;
  --ion-color-secondary-rgb: 248, 179, 48;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #f0a51b;
  --ion-color-secondary-tint: #f6d193;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  /** light background **/

  --ion-color-light-bg: #2b2b2b;
  --ion-color-light-bg-rgb: 43, 43, 43;
  --ion-color-light-bg-contrast: #ffffff;
  --ion-color-light-bg-contrast-rgb: 255, 255, 255;
  --ion-color-light-bg-shade: #262626;
  --ion-color-light-bg-tint: #404040;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #1c1c1d;

  --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #585454;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #804848;

  --ion-tab-bar-background: #534444;

  --ion-card-background: #5e5252;

  /** primary background **/
  --ion-color-primary-bg: #000000;
  --ion-color-primary-bg-rgb: 0, 0, 0;
  --ion-color-primary-bg-contrast: #ffffff;
  --ion-color-primary-bg-contrast-rgb: 255, 255, 255;
  --ion-color-primary-bg-shade: #e0e0e0;
  --ion-color-primary-bg-tint: #000000;
}
