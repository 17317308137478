/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.bg-light {
  --background: var(--ion-color-light-bg);
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.c-pointer {
  cursor: pointer;
}

.bottom-sheet-popover .popover-content {
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  top: calc(100% - 150px) !important;
  // height: 20%;
  max-height: 150px;
  min-height: 150px;
  border-radius: 0 !important;
}
//   ion-app {
//     /*animation of native keyboard show*/
//     transition: margin 300ms;
// }

ion-badge {
  font-size: 10px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.cart-modal {
  --height: 60%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
  }
}

.menu-pop {
  --height: 60%;
  // --min-height: 60%;
  --width: 80%;
  --offset-x: 0px;
  --offset-y: 0px;
  align-items: end;
  // padding-top: 20px;
  padding-bottom: 70px;
  backdrop-filter: blur(2px);
  --box-shadow: none;
  &::part(content) {
    border-radius: 16px;
    background: transparent;
    // padding-bottom: 70px;
    // box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

.shift-menu-pop {
  padding-bottom: 100px;
}

// .menu-pop ::ng-deep {
//   .modal-wrapper {
//     border-radius: 16px;
//     .ion-page {
//       justify-content: start;
//     }
//   }
// }

.order-preference-modal {
  --height: 50%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

.order-status-modal {
  --height: 50%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

// BEGINS: Top Action Bar Styles
.top_action_bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--ion-color-primary-bg);
  z-index: 9;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);

  &_container {
    display: flex;
    padding: 12px 20px;
  }
  &-btn {
    position: absolute;
    right: 20px;
  }

  &-icon_wrapper {
    background-color: var(--ion-color-primary-bg);
    border-radius: 8px;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    // box-shadow: 0 0 8px rgba(0,0,0,0.025);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 20px;
    }
  }

  &-title {
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
  }
}
// ENDS: Top Action Bar Styles

.footer_menu,
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.payment-modal {
  --height: 80%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    // border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

.item-customization-modal {
  --height: 80%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    .ion-page {
      justify-content: start;
    }
  }
}
.repeat-customization-modal {
  --height: 40%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    .ion-page {
      justify-content: start;
    }
  }
}

.customer-list-modal {
  --height: 90%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    .ion-page {
      justify-content: start;
    }
  }
}

.add-table-modal {
  --height: 90%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    .ion-page {
      justify-content: start;
    }
  }
}

.coupons-modal {
  --height: 90%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    .ion-page {
      justify-content: start;
    }
  }
}

.variant-modal {
  --height: 50%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.2);
  }
  .modal-wrapper {
    .ion-page {
      justify-content: start;
    }
  }
}

.variant-modal ::part backdrop {
  z-index: 20;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

ion-toast {
  transform: translateY(-50px);
}

.no-data-found {
  text-align: center;
  font-size: 0.8em;
  margin: 16px 0 8px;
}

// .ng-select.custom {
//   border: 0px;
//   min-height: 0px;
//   border-radius: 0;
// }
// .ng-select.custom .ng-select-container {
//   min-height: 0px;
//   border-radius: 0;
// }

// Combo Create ng-select
ng-select.custom {
  .ng-select-container {
    .ng-value-container {
      height: 42px;
      align-items: center;
      .ng-input {
        input {
          width: 100% !important;
          padding: 10px;
          border: 1px solid #ececec;
          border-radius: 8px;
          font-size: 14px;
          // margin-top: 8px;
          // background-color: red;
          &:focus-visible {
            outline: none;
          }
        }
      }
      .ng-value {
        padding-left: 8px;
      }
    }
  }
  .ng-clear-wrapper {
    align-self: center;
  }
}

// For Upsell Ng-Select
.ng-dropdown-panel {
  opacity: 1;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  padding: 8px;
  .ng-dropdown-panel-items {
    .ng-option {
      padding: 4px 0 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.desktop-only {
  display: none !important;
}
.mobile-only {
  display: block;
}

@media (width > 500px) {
  .mobile-only {
    display: none !important;
  }
  .desktop-only {
    display: block !important;
  }
}
/*
 * Optional CSS
 * -----------------------------------
 */

/* This sets a different background and item background in light mode on ios */
.ios body {
  --ion-background-color: #f2f2f6;
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #fff;
}

/* This sets a different background and item background in light mode on md */
.md body {
  --ion-background-color: #f9f9f9;
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #fff;
}

/* This is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
